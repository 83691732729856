// src/components/PrivacyPolicy.js

import React from 'react';

export const PrivacyPolicy = () => {
    return (
        <section className="privacy-policy">
            <div className="container">
                <h2>Privacy Policy</h2>
                <p><strong>Last Updated: January 22, 2025</strong></p>

                <p>
                    BlueMeta Technologies (“we,” “us,” or “our”) values your privacy and is committed to protecting the personal information you provide while using the “MyBlockCounts” mobile application (“App”). This Privacy Policy explains how we collect, use, and share your information, as well as your rights regarding your data. By using the App, you consent to the practices described in this Privacy Policy.
                </p>

                <h3>1. Information We Collect</h3>

                <h4>a. Personal Information</h4>
                <p>
                    <strong>Details Collected:</strong> First name, last name, email address, and password during account registration.<br />
                    <strong>Purpose:</strong> To create and manage your user account, facilitate communication, and personalize your experience within the App.
                </p>

                <h4>b. Location Data</h4>
                <p>
                    <strong>Details Collected:</strong> IP-based and GPS-based location data when you grant permission within the App.<br />
                    <strong>Purpose:</strong> To pinpoint and display your current location on the map, enable location-based functionalities, and enhance your overall experience.<br />
                    <strong>Usage Restrictions:</strong> Location data is used solely for providing core functionalities within the App. We do not track you across other apps or use your location for advertising.<br />
                    <strong>Optional:</strong> If you choose not to grant location permission, certain features may be limited or unavailable.
                </p>

                <h4>c. User-Generated Content</h4>
                <p>
                    <strong>Details Collected:</strong> Data submitted through surveys, including form responses and photos.<br />
                    <strong>Purpose:</strong> To allow you to contribute to and edit survey submissions, participate in community engagement initiatives, and support research activities.
                </p>

                <h4>d. Usage Data</h4>
                <p>
                    <strong>Details Collected:</strong> Information about how you use the App, such as features accessed and actions performed.<br />
                    <strong>Purpose:</strong> To maintain and improve the functionality and security of the App.<br />
                    <strong>Note:</strong> We do not track or analyze user behavior beyond what is necessary for maintaining App performance and security, and we do not track usage across other apps or websites.
                </p>

                <h4>e. Device or Other IDs</h4>
                <p>
                    <strong>Details Collected:</strong> Unique identifiers generated by our service providers (e.g., Firebase Installation IDs or tokens).<br />
                    <strong>Purpose:</strong> To facilitate secure authentication, maintain user sessions, and support core App functionality (e.g., delivering real-time updates).<br />
                    <strong>Usage Restrictions:</strong> We do not use these identifiers to track you for advertising or to collect additional personal data beyond what is disclosed in this Privacy Policy.
                </p>

                <h3>2. How We Use Your Information</h3>
                <p>We utilize the collected information to:</p>
                <ul>
                    <li><strong>Map Functionality:</strong> Build and display interactive maps that highlight locations relevant to environmental surveys.</li>
                    <li><strong>User Contributions:</strong> Enable you to contribute to, edit, and manage survey submissions effectively.</li>
                    <li><strong>App Maintenance:</strong> Maintain the security, performance, and functionality of the App.</li>
                    <li><strong>Support Services:</strong> Provide customer support and respond to user inquiries.</li>
                    <li><strong>Research and Community Engagement:</strong> Collaborate with academic and research institutions to support environmental studies and community initiatives.</li>
                </ul>
                <p><strong>We do not use your information for:</strong></p>
                <ul>
                    <li>Marketing purposes, including push notifications or email campaigns.</li>
                    <li>Selling or distributing your data to third parties for advertising or other commercial uses.</li>
                    <li>Recording or monitoring audio, as the App does not request microphone access.</li>
                </ul>

                <h3>3. Data Sharing</h3>

                <h4>a. With Research Partners</h4>
                <p>
                    <strong>Entities:</strong> University of Maryland’s CEEJH Lab.<br />
                    <strong>Purpose:</strong> To support research and community engagement initiatives related to environmental studies.<br />
                    <strong>Data Shared:</strong> Aggregate data and anonymized user-generated content, as required for legitimate research purposes.
                </p>

                <h4>b. With Service Providers</h4>
                <p>
                    <strong>Entities:</strong> Third-party providers such as Firebase and Map Service APIs.<br />
                    <strong>Purpose:</strong> To host data, manage authentication, provide mapping functionalities, and ensure the smooth operation of the App.<br />
                    <strong>Data Shared:</strong> Personal information (e.g., email, location, device identifiers) may be processed and stored by these providers according to their privacy policies. We only share information necessary to deliver the App’s core features.
                </p>

                <h4>c. Legal Compliance</h4>
                <p>
                    <strong>Circumstances:</strong> We may disclose your information if required by law or in response to valid legal requests by public authorities.<br />
                    <strong>Scope:</strong> Only the information necessary to comply with legal obligations.
                </p>
                <p><strong>Note:</strong> We do not sell or share your personal information with third parties for marketing or advertising purposes.</p>

                <h3>4. User Rights and Choices</h3>
                <ul>
                    <li>
                        <strong>Access and Edit:</strong> You can view and edit your survey data and personal information within the App.<br />
                        <strong>How-To:</strong> Navigate to your account settings to update your information and manage survey submissions.
                    </li>
                    <li>
                        <strong>Delete Data (Account Deletion):</strong> You can permanently delete your account and all associated data at any time.<br />
                        <ul>
                            <li>To delete your account, open the App’s <em>Settings</em> screen and tap the <em>“Delete Account”</em> button. Once confirmed, your Firebase Auth record and all associated personal data (including survey submissions) will be removed from our systems.</li>
                            <li>If you encounter any issues with the account deletion process or would prefer to request account deletion outside of the App, please contact us at <a href="mailto:malik@bluemetatech.com">malik@bluemetatech.com</a>.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Location Data Consent:</strong> By granting location permission, you consent to the collection and use of your location data.<br />
                        <strong>Control:</strong> You can manage location permissions through your device settings. Disabling location access may limit certain functionalities within the App.
                    </li>
                    <li>
                        <strong>Opt-Out of Data Sharing:</strong> You can choose to limit data sharing with research partners by adjusting your survey participation settings within the App.<br />
                        <strong>How-To:</strong> Navigate to the <em>Privacy Settings</em> section in the App to manage data sharing preferences.
                    </li>
                </ul>

                <h3>5. Data Retention</h3>
                <ul>
                    <li>User data, including survey responses and photos, is retained indefinitely unless you delete your account.</li>
                    <li>We may implement additional data retention guidelines in the future as required by law or operational needs.</li>
                </ul>

                <h3>6. Data Security</h3>
                <p>
                    We implement industry-standard measures to protect your data, including:
                </p>
                <ul>
                    <li><strong>Encryption:</strong> Data is encrypted in transit and at rest to prevent unauthorized access.</li>
                    <li><strong>Secure Authentication:</strong> Robust authentication processes protect user accounts.</li>
                    <li><strong>Third-Party Security:</strong> Data stored with service providers like Firebase is secured per their respective policies.</li>
                    <li><strong>Data Storage Location:</strong> All data is stored in the United States through Firebase services.</li>
                </ul>
                <p><strong>Note:</strong> While we strive to protect your data, no method of transmission over the internet or electronic storage is 100% secure.</p>

                <h3>7. Third-Party Services</h3>

                <h4>a. Firebase</h4>
                <p>
                    <strong>Purpose:</strong> Provides backend services such as authentication, database management, and data storage.<br />
                    <strong>Data Handling:</strong> Your data is stored and processed by Firebase in compliance with their privacy policies. They may collect device identifiers (e.g., installation IDs) to support authentication and app performance.<br />
                    <strong>Access:</strong> Firebase has access to your personal information only to provide these necessary services.
                </p>

                <h4>b. Map Service APIs</h4>
                <p>
                    <strong>Purpose:</strong> Enables interactive mapping functionalities within the App.<br />
                    <strong>Data Handling:</strong> Location data is processed by map service providers in accordance with their privacy policies.<br />
                    <strong>Access:</strong> These providers may have access to your location data solely for rendering maps and providing location-based features.
                </p>
                <p><strong>Note:</strong> We encourage you to review the privacy policies of these third-party services to understand how they handle your information.</p>

                <h3>8. Minors</h3>
                <p>
                    The App is intended for users aged <strong>13 and older</strong>. We do not knowingly collect personal information from children under 13. If you become aware that a child under 13 has provided us with personal information, please contact us immediately at <a href="mailto:malik@bluemetatech.com">malik@bluemetatech.com</a>. We will take steps to delete such information from our systems.
                </p>

                <h3>9. Updates to This Privacy Policy</h3>
                <p>
                    We may update this Privacy Policy to reflect changes to our practices or for operational, legal, or regulatory reasons. If changes are made, we will notify users by updating the <strong>“Last Updated”</strong> date at the top of this page. Continued use of the App after any updates indicates acceptance of the revised policy.
                </p>

                <h3>10. Contact Us</h3>
                <p>
                    For questions, concerns, or requests regarding your data or this Privacy Policy, please contact us at:<br />
                    <strong>Email:</strong> <a href="mailto:malik@bluemetatech.com">malik@bluemetatech.com</a>
                </p>
            </div>
        </section>
    );
};
